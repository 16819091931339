<template>
  <div
    class="mx-n4 mt-n4"
  >
    <appBarMobile
      color="primary"
      show-back-button
      :show-nav-icon="false"
      title="Help"
    />
    <span
      class="staticPages"
    >
      <ads-expansion-panel
        :items="helpItems"
      />
    </span>
  </div>
</template>
<script>
import appBarMobile from "@/components/appBarMobile";
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core';
export default {
  computed:{
    helpItems(){
     return this.items.map(item => { return { title: item.question , content: item.answer}});
    }
  },
  data() {
    return {
      items: [
    {
        "question" : "Who has access to the staff portal app?",
        "answer"   : "<p>The staff portal app is available to all staff.</p>"
    },{
        "question" : "How do I change the way I log in and swap between username/password and device passcode or biometrics?",
		"answer"   : "To log into the app quickly without having to use your standard DoE username and password each time you open the app, we recommend you enable a passcode on your device:<br> <blockquote><p>1. Via the Device Settings (Cog icon) from your device's home screen, follow the instructions within the Touch ID &amp; Passcode option. This shall allow you to set up both Passcode and Biometric login (depending on your device's capability) which will then be used for secure access to your device and can also be used for the Staff Portal app,</p>2. Within the Staff Portal app, go to the Settings tab. Within the App '<i>SECURITY</i>' section, tap the slider titled Face ID/Touch ID so that it's \"on\". This shall enable both Passcode and Biometrics as they're interlinked on your devices.</blockquote>"
    },{
        "question" : "Do I need to use Touch ID or Face ID?",
        "answer"   : "<p>No, you don't have to use them. We do recommend it as it saves you time.</p>"
    },{
        "question" : "How do I turn on/off my notifications?",
        "answer"   : "<ol>\r\n  <li>Go into <strong>Settings</strong> on your Phone.</li>\r\n  <li>Select <strong>Notifications</strong>.</li>\r\n  <li>Select <strong>NSW DoE staff portal app</strong>.</li>\r\n  <li>Select your preferred notification option.</li>\r\n</ol>\r\n<p>You will still receive notifications inside the app.</p>"
    },{
        "question" : "Why don't changes I make to Staff Essentials on my laptop appear on the app?",
        "answer"   : "<p>The web portal and the portal app synch instantly. If your changes are not synching please contact EDConnect.</p>"
    },{
        "question" : "How do I email a SchoolBiz article to a colleague using the app?",
        "answer"   : "<ol>\r\n  <li>Select the article that you want to share and <strong>swipe left</strong>.</li>\r\n  <li>Select <strong>Share</strong>.</li>\r\n  <li>Select <strong>Mail</strong>.</li>\r\n  <li>Once the email application opens type in your colleague\u2019s email address and select <strong>Send</strong>. The selected SchoolBiz article attaches automatically.</li>\r\n</ol>\r\n<p>You can also share the article by using the share symbol at the top of the screen.</p>"
    },{
        "question" : "How do I filter SchoolBiz articles? ",
		"answer"   : "You can set up your own filter to view specific SchoolBiz articles that are most relevant to you by choosing the audience, school type and Operational Directorate:<br><blockquote><p>1. Within a SchoolBiz edition, tap on the filter button located in the top right corner of the SchoolBiz page,</p><p>2. From the list of options, select your choice(s) from: <i>Audience, School Type and Operational Directorate.</i> </p>"
    },{
        "question" : "How do I view previous editions of SchoolBiz?",
		"answer"   : "You can view previous editions in the archive:<blockquote><p>1. Within SchoolBiz, tap on '<i>Change</i>' to the right of the week and term numbers in the upper right of the page; or at the bottom of the SchoolBiz edition, tap on '<i>View SchoolBiz archives,</i>'they</p><p>2. Select the school year of the article you are looking for,</p><p>3. Select the term,</p><p>4. Select the week number,</p><p>5. To get back to the current / latest edition, with navigate via the above steps, or tap on the SchoolBiz Notification within your Home screen.'</p></blockquote>"
    },{
        "question" : "How do I remove SchoolBiz articles from my bookmarked items?",
		"answer"   : "Bookmarked items allow you to keep SchoolBiz articles that you may want to save and quickly access later. When you've finished with the article, you can remove from your Bookmarks with the following steps:<blockquote><p>1. Select View Bookmarks towards the bottom of the SchoolBiz edition,</p><p>2. Select the article that you want to remove and swipe left,</p><p>3. Select Remove.</p><p> <b>NOTE:</b> Bookmarks are unique to each device and not replicated between multiple devices if you use the app on more than one device.</p></blockquote>"
    },{
        "question" : "If I change my profile in the app will it change in the web portal?",
        "answer"   : "<p>Yes. If you update your profile in the portal app it will update in the web portal, and vice versa.</p>"
    },{
        "question" : "How do I search for one of my essentials?",
        "answer"   : "<p>Use the search function located at the top of your <strong>Home screen</strong>.</p>\r\n<ol>\r\n  <li>Type the name of the essential application into the search field.</li>\r\n</ol>"
    },{
        "question" : "Why can I only search by first and last name in the directory on the app?",
        "answer"   : "<p>The staff directory search in the portal app is a quick search that allows you to message, call or email staff directly from the search results.</p>\r\n<p>You can only use the advanced search in the web portal staff directory.</p>"
    },{
        "question" : "Who do I contact if I need help?",
        "answer"   : "<p>Call EDConnect during business hours on <strong @click=\"tel('1300 32 32 32')\">1300 32 32 32</strong>.</p>"
    },{
        "question" : "Who do I contact if I have feedback?",
        "answer"   : "<ol>\r\n  <li>Select the <strong>Settings</strong> icon in the bottom right of the staff app home screen.</li>\r\n  <li>Select <strong>Feedback</strong>.</li>\r\n  <li>Send your feedback to the pre-populated email address: <a href='#' @click=\"mailTo('feedback@staffportalapp@det.nsw.edu.au')\">feedback@staffportalapp@det.nsw.edu.au</a></li>\r\n</ol>"
    },{
        "question" : "How do I add my photo to my profile?",
        "answer"   : "<p>You can't at the moment. We will incorporate this improvement into a future update.</p>"
    },{
        "question" : "How do I change or add to my essentials?",
        "answer"   : "<p>You must use the web portal to manage your essentials. Your changes will synch between the web portal and the app portal.</p>"
    },{
        "question" : "Can I request a feature?",
        "answer"   : "<p>We encourage you to suggest any improvements to the app and to request features that will save you time. Use the <strong>Feedback</strong> option to send us your request.</p>"
    }
]
    };
  },
  created(){
      //eslint-disable-next-line
      NativeJS.profileHelpTapped();
      window.scrollTo(0,0);
    },
  components: {
    appBarMobile,
    AdsExpansionPanel
  },
  methods:{
    tel(phone){
      if(phone){
        phone = phone.replace(/ /g,"");
      }
      // eslint-disable-next-line
      if(NativeJS){
        // eslint-disable-next-line
        NativeJS.tel(phone);
      }
    },
    mailTo(email){
      //eslint-disable-next-line
        NativeJS.mailTo(email);
    }
  }
};
</script>

<style scoped>
.default--text{
  font-size:14px;
}
</style>